import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Navbar from './Navbar';
import HeroText from './HeroText';
import Pigerne from './assets/pigerne.jpg'
import Grønsager from './assets/grønsager.jpg'
import Grønsagekasser from './assets/grønsagekasser.jpg'
import Gruppebillede from './assets/gruppebillede.jpg'
import Seeds from './assets/seeds.jpg'
import Seedsihånd from './assets/seedsihånd.jpg'
import Vegetables from './assets/vegetables.jpg'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
        <hero class="flex flex-col h-screen bg-center bg-cover bg-blend-overlay bg-fixed bg-black/30 text-white" style={{backgroundImage: `url(${Vegetables})`}} >
            <Navbar />
            <HeroText />
        </hero>
        <insta>
            <div class="m-10 grid grid-flow-row grid-cols-3 gap-36 justify-center">
                <img src={Pigerne} alt="pigerne" class="size-auto"></img>
                <img src={Grønsager} alt="pigerne" class="size-auto"></img>
                <img src={Grønsagekasser} alt="pigerne" class="size-auto"></img>
                <img src={Gruppebillede} alt="pigerne" class="size-auto"></img>
                <img src={Seeds} alt="pigerne" class="size-auto"></img>
                <img src={Seedsihånd} alt="pigerne" class="size-auto"></img>
            </div>
        </insta>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
